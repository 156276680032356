import { Mixpanel } from '../../mixpanel';
import React, { useEffect } from 'react';

const AppView = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const product_id = urlParams.get("product_id");
  const ifrmaeUrl = `https://1hzi0xnoalh.typeform.com/to/QQQO0zNq?typeform-embed=embed-widget&amp;typeform-medium=embed-sdk&amp;typeform-medium-version=next&amp;#product_id=${product_id}`;

  useEffect(() => {
    Mixpanel.track("page-load", {
      "community_id": product_id,
    });
  }, [product_id]);

  return (
    <main className="flex flex-col  min-h-screen bg-gray-100">
      {/* <header className="bg-white p-4">
        <a
          className="flex justify-center items-center"
          href="https://www.collab.land"
        >
          <img src={logoSrc} alt="Collab.Land" />
        </a>
      </header> */}

      <iframe
        src={ifrmaeUrl}
        title="CollabLand Survey"
        data-testid="iframe"
        className=" min-h-screen w-full mb-4"
      ></iframe>

      {/* <footer className="bg-white p-4 flex flex-row items-center justify-between">
        <div className="flex justify-between items-center gap-4 ml-auto">
          <div className="flex gap-4 flex-row">
            <a
              className="text-gray-500 font-semibold"
              href="https://www.collab.land/privacy-policy "
            >
              Privacy Policy
            </a>
            <a
              className="text-gray-500 font-semibold"
              href="https://www.collab.land/terms-of-service "
            >
              Terms of Service
            </a>
            <a
              className="text-gray-500 font-semibold"
              href="https://www.collab.land"
            >
              ©2023 Collab.Land
            </a>
          </div>
        </div>
      </footer> */}
    </main>
  );
};

export default AppView;
